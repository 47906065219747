const payments = [
  {
    text: "M-Pesa",
    enable: true,
    type: "MPESA_KENYA",
    merchantId: 9,
    img: "../assets/images/home/mpasa.png",
    checkPrecedence: (phone) => {
      return true;
    },
  },
  {
    text: "MTN",
    enable: false,
    type: "MTN_UGANDA",
    merchantId: 10,
    img: "../assets/images/home/MTN.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 700 && phoneHeader <= 706) ||
        (phoneHeader >= 750 && phoneHeader <= 759) ||
        (phoneHeader >= 740 && phoneHeader <= 749)
      ) {
        return true;
      }
      return false;
    },
  },
  {
    text: "Airtel",
    enable: false,
    merchantId: 10,
    type: "AIRTEL_UGANDA",
    img: "../assets/images/home/Airtel.png",
    checkPrecedence: (phone) => {
      return true;
    },
  },
  {
    text: "M-Pesa",
    enable: true,
    type: "MPESA_KENYA",
    merchantId: 35,
    img: "",
    checkPrecedence: (phone) => {
      return true;
    },
  },
];
export default {
  payments: payments.filter((payment) => payment.enable),
};
